import { Component } from "react";

class DropField extends Component {
  render() {
    const { children, className } = this.props;

    return (
      <span
        className={className}
        spellCheck={false}
        style={{ backgroundColor: "#DDD" }}
      >
        {children}
      </span>
    );
  }
}

export default DropField;