import { EditorPlugin } from "@draft-js-plugins/editor";
import { ComponentType, ReactElement } from "react";
import TextAlignmentComponent, { AlignmentPluginsPubParams } from "./TextAlignmentComponent";

export type TextAlignmentPlugin = EditorPlugin & {
  TextAlignment: ComponentType<AlignmentPluginsPubParams>;
};

export default (): TextAlignmentPlugin => {

  const TextAlignment = (props: AlignmentPluginsPubParams): ReactElement => (
    <TextAlignmentComponent {...props} />
  );

  return { TextAlignment };
};
