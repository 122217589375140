import { Component } from "react";
import { Dropdown } from "react-bootstrap";

class FontSizeSelect extends Component {
  constructor() {
    super();
    ["onSelectHandler"].forEach (item => {
      this[item] = this[item].bind(this);
    });
  }

  onSelectHandler(value) {
    return () => {
      this.props.onSelect(value);
    };
  }

  renderOptions() {
    const { selectedValue } = this.props;
    return this.props.values.map(value => {
      return <Dropdown.Item key={value} onClick={this.onSelectHandler(value)} active={selectedValue === value}>{value}</Dropdown.Item>;
    });
  }

  render() {
    const { additionalClasses, svgClasses, selectedValue } = this.props;

    return (
      <Dropdown id={`dropdown-font-select-${Math.random()}`} className={additionalClasses} >
        <Dropdown.Toggle variant="secondary" className={`custom-rte-btn ${svgClasses}`}>
          {selectedValue}
          <i className="fa-regular fa-text-height ml-5"/>
        </Dropdown.Toggle>
        <Dropdown.Menu className="super-colors">
          { this.renderOptions() }
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default FontSizeSelect;
