import { getSelectedBlocksList } from "./block";

/**
 * Function returns style at a offset.
 */
export function getStyleAtOffset(block, stylePrefix, offset) {
  const styles = block.getInlineStyleAt(offset).toList();
  const style = styles.filter(s => s.startsWith(stylePrefix));
  if (style && style.size > 0) {
    return style.get(0);
  }
  return undefined;
}

/**
 * Function returns color at a offset or default RTE color if no style defined at offset
 */
export function getColorAtOffsetOrDefault(block, offset) {
  const colorStyleAtOffset = getStyleAtOffset(block, "fontColor", offset);
  return colorStyleAtOffset === undefined ? "" : colorStyleAtOffset.replace("fontColor", "");
}

/**
 * Function returns size at a offset.
 */
function getCurrentInlineStyle(editorState, stylePrefix) {
  const styles = editorState.getCurrentInlineStyle().toList();
  const style = styles.filter(s => s.startsWith(stylePrefix));
  if (style && style.size > 0) {
    return style.get(0);
  }
  return undefined;
}

/**
 * Function returns an object of custom inline styles currently applicable.
 */
export function getSelectionCustomInlineStyle(editorState, styles) {
  if (editorState && styles && styles.length > 0) {
    const currentSelection = editorState.getSelection();
    const inlineStyles = {};
    if (currentSelection.isCollapsed()) {
      styles.forEach(s => {
        inlineStyles[s] = getCurrentInlineStyle(editorState, s);
      });
      return inlineStyles;
    }
    const start = currentSelection.getStartOffset();
    const end = currentSelection.getEndOffset();
    const selectedBlocks = getSelectedBlocksList(editorState);
    if (selectedBlocks.size > 0) {
      for (let i = 0; i < selectedBlocks.size; i += 1) {
        let blockStart = i === 0 ? start : 0;
        let blockEnd = i === selectedBlocks.size - 1 ? end : selectedBlocks.get(i).getText().length;
        if (blockStart === blockEnd && blockStart === 0) {
          blockStart = 1;
          blockEnd = 2;
        } else if (blockStart === blockEnd) {
          blockStart -= 1;
        }
        for (let j = blockStart; j < blockEnd; j += 1) {
          if (j === blockStart) {
            styles.forEach(s => {
              inlineStyles[s] = getStyleAtOffset(selectedBlocks.get(i), s, j);
            });
          } else {
            styles.forEach(s => {
              if (inlineStyles[s] && inlineStyles[s] !== getStyleAtOffset(selectedBlocks.get(i), s, j)) {
                inlineStyles[s] = undefined;
              }
            });
          }
        }
      }
      return inlineStyles;
    }
  }
  return {};
}
