"use strict";
import { Component } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import includes from "lodash/includes";
import pull from "lodash/pull";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import truncate from "truncate";
import Filter from "../components/Filter.react";

const DEFAULT_ITEM_COLOR = "#777";
const FILTER_WINDOW_BORDER_MARGIN = 20;

class FilterDropdown extends Component {
  constructor(props) {
    super(props);
    [
      "_deleteItem",
      "_onInputChange",
      "_sortItems",
      "_onEnter",
      "_onExited",
      "_close",
      "_onPageSubmit",
      "onUpdateState",
      "onToggle",
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    const { selectedItemIds, intermediateItemIds, filterMaxHeight } = props;
    this.state = {
      selectedItemIds,
      intermediateItemIds,
      searchValue: null,
      placement: "bottom",
      filterMaxHeight: filterMaxHeight,
      displayList: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedItemIds } = this.props;
    const { displayList } = this.state;

    if (selectedItemIds && prevProps.selectedItemIds && !isEqual(selectedItemIds, prevProps.selectedItemIds)) {
      this.setState({ selectedItemIds: selectedItemIds });
    }
    if (!prevProps.submitLaunched && this.props.submitLaunched) {
      this._onPageSubmit();
    }

    if (displayList) {
      this.handleFilterPlacement();
      this.handleFilterHeight();
    }
  }

  handleFilterPlacement() {
    const { translationKey, filterMinHeight } = this.props;
    const { placement } = this.state;

    const filterBtnOffsetTop = document.getElementById(translationKey).getBoundingClientRect().top || 0;

    if (filterBtnOffsetTop + filterMinHeight < window.innerHeight * 0.7) {
      if (placement != "bottom") this.setState({ placement: "bottom" });
    } else {
      if (placement != "top") this.setState({ placement: "top" });
    }
  }

  handleFilterHeight() {
    const { translationKey, filterMinHeight } = this.props;
    const { filterMaxHeight } = this.state;

    const popover = document.getElementById(`${translationKey}-popover`);
    const filterBtnOffsetTop = document.getElementById(translationKey).getBoundingClientRect().top || 0;
    const filterPanelHeight = this.calcNewHeightForFilterPanel(filterBtnOffsetTop, popover);
    const filterPanelNewHeight = filterPanelHeight < 400 ? filterPanelHeight : 400;

    if (filterPanelNewHeight > filterMinHeight && filterPanelNewHeight != parseFloat(filterMaxHeight)) {
      this.setState({ filterMaxHeight: `${filterPanelNewHeight}px` });
    }
  }

  calcNewHeightForFilterPanel(filterBtnOffsetTop, popover) {
    const { translationKey, filterMinHeight } = this.props;

    const popoverBtnHeight = document.getElementById(translationKey).clientHeight;
    const filterCardHeight = document.getElementById(`${translationKey}-filter-card`).clientHeight;
    const popoverHeaderHeight = popover.clientHeight - filterCardHeight;

    // if ppopover placement on top
    let newPopoverHeight = filterBtnOffsetTop - popoverHeaderHeight;

    // if ppopover placement on bottom
    if (filterBtnOffsetTop + filterMinHeight < window.innerHeight * 0.7) {
      newPopoverHeight = window.innerHeight - popoverBtnHeight - filterBtnOffsetTop - popoverHeaderHeight;
    }

    return newPopoverHeight - FILTER_WINDOW_BORDER_MARGIN;
  }

  _onPageSubmit() {
    this._onExited();
  }

  _deleteItem(e) {
    const { selectedItemIds } = this.state;
    const { value } = e.target.attributes.value;
    pull(selectedItemIds, value);
    this.setState({ selectedItemIds });
    this.props.onChange(selectedItemIds);
  }

  _close() {
    this.setState({ displayList: false });
  }

  _onEnter() {
    this._sortItems();
    this.setState({ searchValue: null });
    const { onFocus } = this.props;
    onFocus();
  }

  _onExited() {
    const { selectedItemIds } = this.state;
    const { onBlur, onChange } = this.props;
    onChange(selectedItemIds);
    this.setState({ searchValue: null });
    onBlur();
  }

  _itemCell(id) {
    const { items, itemIdKey, itemNameKey, itemColorKey } = this.props;
    const idKey = itemIdKey || "id";
    const colorKey = itemColorKey || "color";

    const item = items.find(item => {
      return item[idKey] == id;
    });
    if (!item) {
      return;
    }
    let cellStyle = {
      backgroundColor: item[colorKey] || DEFAULT_ITEM_COLOR
    };
    return (
      <li key={id}>
        <span className="badge rounded-pill" style={cellStyle}>
          {truncate(item[itemNameKey], 24)}
          <div className="filter-cell-close">
            <i className="fa-regular fa-xmark" value={id} onClick={this._deleteItem} ></i>
          </div>
        </span>
      </li>
    );
  }

  _itemCellsGroup(itemIds) {
    if (itemIds.length === 0) return null;

    const itemCells = itemIds.map(id => {
      return this._itemCell(id);
    });
    return (
      <ul className="filter-cells-group d-inline-block list-inline mt-5" style={{ width: "100%" }}>
        {itemCells}
      </ul>
    );
  }

  _onInputChange(e) {
    this.setState({ searchValue: e.target.value });
  }

  _sortItems() {
    const { selectedItemIds } = this.state;
    const { items, sortItems, selectedFirst, itemIdKey, itemNameKey } = this.props;
    const idKey = itemIdKey || "id";

    if (sortItems || selectedFirst) {
      items.sort((a, b) => {
        if (selectedFirst) {
          if (includes(selectedItemIds, a[idKey]) && !includes(selectedItemIds, b[idKey])) {
            return -1;
          } else if (!includes(selectedItemIds, a[idKey]) && includes(selectedItemIds, b[idKey])) {
            return 1;
          }
        }
        if (sortItems) {
          return (a[itemNameKey].toLowerCase() < b[itemNameKey].toLowerCase() ? -1 : 1);
        }
        return 0;
      });
    }
  }

  onUpdateState(stateValue) {
    const { onChange, liveChange } = this.props;
    if (liveChange && onChange) onChange(stateValue.selectedItemIds);
    this.setState(stateValue);
  }

  _renderOptions() {
    const { searchValue, selectedItemIds, intermediateItemIds, filterMaxHeight } = this.state;
    const { translationKey } = this.props;

    return (
      <Popover id={`${translationKey}-popover`} className="filter-popover">
        <div ref={`${translationKey}-popover`}>
          <div className="popover-header">
            <div className="popover-title">
              <span>{ I18n.t(`react.reports.${translationKey}.filter_popover_title`) }</span>
            </div>
            <div className="popover-close">
              <button type="button" onClick={this._close} className="btn-close" aria-label={I18n.t("close")}></button>
            </div>
          </div>
          <Filter
            {...this.props}
            selectedItemIds={selectedItemIds}
            intermediateItemIds={intermediateItemIds}
            searchValue={searchValue}
            closeParent={this._close}
            onChange={this.onUpdateState}
            maxHeight={filterMaxHeight}
            translationKey={translationKey}
          />
        </div>
      </Popover>
    );
  }

  onToggle(value) {
    this.setState({ displayList: value });
  }

  render() {
    const { translationKey, showCells, children, title, additionalClasses, wrapperClasses, popoverAdditionalClasses, showBadgeNbItems, disabled, onClick, id } = this.props;
    const { selectedItemIds, placement, displayList } = this.state;
    const cellsGroup = showCells == false ? "" : this._itemCellsGroup(selectedItemIds);
    const badgeNbItems = showBadgeNbItems && selectedItemIds.length > 0 ? (
      <span className="badge rounded-pill bg-secondary">{ selectedItemIds.length }</span>
    ) : null;

    const popoverToggle = isEmpty(children) ? (
      <div className={`popover-toggle btn btn-secondary ${disabled ? "disabled" : null} ${popoverAdditionalClasses || ""}`} onClick={onClick} ref={`${translationKey}-popover-toggle`}>
        <div className="options-block-title toggable">
          {title || I18n.t(`react.reports.${translationKey}.filter_title`)} { badgeNbItems } <span><i className="fa-solid fa-caret-down"></i></span>
        </div>
      </div>
    ) : children;

    if (disabled) return <span className={additionalClasses}> {popoverToggle} </span>;

    return (
      <div className={`filter-block ${wrapperClasses || ""}`} style={{ maxWidth: "400px", width: "100%" }} id={id}>
        <OverlayTrigger
          placement={placement}
          trigger="click"
          overlay={this._renderOptions()}
          show={displayList}
          onToggle={this.onToggle}
          onEnter={this._onEnter}
          onExited={this._onExited}
          rootClose
        >
          <span className={`d-block fit-content ${additionalClasses}`}>{popoverToggle}</span>
        </OverlayTrigger>
        {cellsGroup}
      </div>
    );
  }
}

export default FilterDropdown;

FilterDropdown.propTypes = {
  showBadgeNbItems: PropTypes.bool,
  disabled: PropTypes.bool,
  sortItems: PropTypes.bool.isRequired,
  selectedFirst: PropTypes.bool.isRequired,
  multipleSelect: PropTypes.bool.isRequired,
  hasSearch: PropTypes.bool.isRequired,
  hasSelectAll: PropTypes.bool.isRequired,
  showCells: PropTypes.bool.isRequired,
  nbItemsDisplay: PropTypes.bool.isRequired,
  selectedItemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  intermediateItemIds: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  liveChange: PropTypes.bool,
  itemIdKey: PropTypes.string,
  itemNameKey: PropTypes.string
};

FilterDropdown.defaultProps = {
  showBadgeNbItems: false,
  disabled: false,
  sortItems: true,
  showCells: true,
  nbItemsDisplay: false,
  selectedFirst: true,
  multipleSelect: true,
  hasSearch: true,
  hasSelectAll: true,
  selectedItemIds: [],
  intermediateItemIds: [],
  items: [],
  itemNameKey: "name",
  onClick: null,
  onChange: null,
  title: undefined,
  allowUnselect: false,
  onFocus: () => {},
  onBlur: () => {},
  liveChange: false,
  filterMinHeight: 120, // minimum decent height for filter panel
  filterMaxHeight: null // maximum height for filter panel depending on overflow with browser window
};
