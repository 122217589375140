import { Component } from "react";
import PropTypes from "prop-types";
import uniq from "lodash/uniq";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SketchPicker } from "react-color";
import { COLORS } from "../constants/Constants";

const colorSquareProperties = {
  height: "100%",
  width: "100%",
  cursor: "pointer",
  position: "relative",
  outline: "none",
  borderRadius: "3px"
};

class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.onColorChange = this.onColorChange.bind(this);

    this.state = {
      selectedColor: this.props.selectedColor || this.props.colors[0]
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedColor } = this.props;
    if (prevProps.selectedColor !== selectedColor && selectedColor !== this.state.selectedColor) {
      this.setState({ selectedColor });
    }
  }

  onColorChange({ hex }) {
    this.setState({
      selectedColor: hex
    });
    this.props.setColor(hex);
  }

  overlayPlacement() {
    const { placement } = this.props;
    return placement || "bottom";
  }

  colors() {
    const { colors, event } = this.props;
    const { selectedColor } = this.state;
    let selectableColors = colors;

    if (event && event.primary_color && event.secondary_color) {
      selectableColors = [event.primary_color, event.secondary_color, ...selectableColors];
    }

    if (selectedColor && !selectableColors.includes(selectedColor))
      selectableColors = [selectedColor, ...selectableColors];

    return uniq(selectableColors);
  }

  renderPopover() {
    const { selectedColor } = this.state;

    return (
      <Popover id="color-picker-popover">
        <SketchPicker
          disableAlpha={true}
          color={selectedColor}
          presetColors={this.colors()}
          onChangeComplete={this.onColorChange}
        />
      </Popover>
    );
  }

  colorSquare() {
    const { selectedColor } = this.state;
    const { colorSquareClass } = this.props;

    return <div className={colorSquareClass}>
      <div style={{ ...colorSquareProperties, background: selectedColor }}></div>
    </div>;
  }

  render() {
    const { selectedColor } = this.state;
    const { width, height, children, displayValue, svgClasses, additionalClasses, displayColorSquare } = this.props;

    const style = children ? (
      {}
    ) : (
      { backgroundColor: selectedColor, width, height }
    );

    return (
      <OverlayTrigger trigger="click" rootClose placement={this.overlayPlacement()} overlay={this.renderPopover()}>
        <button type="button" className={`btn btn-secondary color-picker-btn ${additionalClasses} ${svgClasses}`}>
          { !displayColorSquare && <div className="color-proof" style={style}></div> }
          { displayValue && <span className="color-value">{selectedColor}</span> }
          { displayColorSquare && this.colorSquare() }
          { children }
        </button>
      </OverlayTrigger>
    );
  }
}

ColorPicker.defaultProps = {
  width: "20px",
  height: "20px",
  colors: COLORS,
  displayValue: false
};

ColorPicker.propTypes = {
  setColor: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
  displayValue: PropTypes.bool,
  event: PropTypes.object // if event is given, primary and secondary color will be directly available in the picker
};

export default ColorPicker;
