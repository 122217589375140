import { Component } from "react";
import RichTextEditor from "./RichTextEditor.react";

interface Props {
  html: string;
  htmlId: string;
  draftjsContent: any;
  inputName: string;
  toolbarSize: string;
  context?: string;
  textGeneratorPrePromptKey?: string;
}

interface State {
  html: string;
  draftjsContent: any;
}

class RichTextEditorErb extends Component<Props, State> {
  constructor(props) {
    super(props);
    [
      "onContentChange"
    ].forEach(fn => this[fn] = this[fn].bind(this));
    this.state = { html: props.html || "", draftjsContent: props.draftjsContent || {} };
  }

  onContentChange(html: string, draftjsContent: any): void {
    this.setState({ html, draftjsContent });
  }

  renderDraftjsContentHiddenFields(obj: any, name: string): any {
    if (obj.constructor == Array) {
      return obj.map(elem => {
        return this.renderDraftjsContentHiddenFields(elem, name + "[]");
      });
    } else if (obj.constructor == Object) {
      return Object.entries(obj).map(([key, value]) => {
        return this.renderDraftjsContentHiddenFields(value, name + `[${key}]`);
      });
    }

    return <input type="hidden" name={name} key={name} value={obj} />;
  }

  renderEmptyDraftJsEntityMapValue(obj: any, name: string): any {
    //If no entities like links are written in RTE, draftjsContent object will have {} as a value for entityMap key.
    //Rails will remove the entityMap key from params since its value is empty
    //We have to force a null value for the [draftjs_content][entityMap] key in rails params to have a complete draftjs_content hash to save
    //Otherwise, RTE will crash when trying to render the saved text.
    if (this.draftJsContentEntityMapIsEmpty(obj)) {
      return <input type="hidden" name={name + "[draftjs_content][entityMap]"} value={""} />;
    }
  }

  draftJsContentEntityMapIsEmpty(draftjsContent: any): boolean {
    return Object.keys(draftjsContent).length > 0 && Object.keys(draftjsContent.entityMap).length === 0;
  }

  renderHiddenFields(): JSX.Element {
    const { inputName } = this.props;
    const { html, draftjsContent } = this.state;

    return <>
      <input type="hidden" name={inputName + "[html]"} value={html} />
      { this.renderDraftjsContentHiddenFields(draftjsContent, inputName + "[draftjs_content]") }
      { this.renderEmptyDraftJsEntityMapValue(draftjsContent, inputName) }
    </>;
  }

  render(): JSX.Element {
    const { html, draftjsContent } = this.state;
    const { htmlId, toolbarSize, context, textGeneratorPrePromptKey } = this.props;

    return <div id={ htmlId }>
      <RichTextEditor
        draftjsContent={draftjsContent}
        content={html}
        toolbarSize={toolbarSize}
        onBlur={this.onContentChange}
        context={context}
        textGeneratorPrePromptKey={textGeneratorPrePromptKey || ""}
      />
      { this.renderHiddenFields() }
    </div>;
  }
}

export default RichTextEditorErb;
