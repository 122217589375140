import { EditorState } from "draft-js";
import { ButtonHTMLAttributes, ComponentType } from "react";
import AlignTextRightButton from "./AlignTextRightButton";
import AlignTextCenterButton from "./AlignTextCenterButton";
import AlignTextLeftButton from "./AlignTextLeftButton";
import JustifyTextButton from "./JustifyTextButton";
import createTextAlignmentButton from "./createTextAlignmentButton";

export interface DraftJsButtonTheme {
  // CSS classes to apply
  active?: string;
  button?: string;
  buttonWrapper?: string;
}

export interface DraftJsButtonProps {
  theme: DraftJsButtonTheme;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
}

export interface DraftJsBlockAlignmentButtonProps extends DraftJsButtonProps {
  alignment: string | null;
  setAlignment(val: { alignment: string }): void;
}
export interface DraftJsStyleButtonProps extends DraftJsButtonProps {
  setEditorState(editorState: EditorState): void;
  getEditorState(): EditorState;
}

export type DraftJsBlockAlignmentButtonType =
  ComponentType<DraftJsBlockAlignmentButtonProps>;
export type DraftJsStyleButtonType = ComponentType<DraftJsStyleButtonProps>;

export {
  createTextAlignmentButton,
  AlignTextRightButton,
  AlignTextCenterButton,
  AlignTextLeftButton,
  JustifyTextButton,
};
