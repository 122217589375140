import { Component } from "react";
import { Button } from "react-bootstrap";

class StyleButton extends Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    const { icon, label, active, svgClasses } = this.props;
    const iconNode = icon ? (<i className={icon}></i>) : label;

    return (
      <Button variant="secondary" className={`RichEditor-styleButton custom-rte-btn ${svgClasses}`} onMouseDown={this.onToggle} active={active} >
        {iconNode}
      </Button>
    );
  }
}

export default StyleButton;
