import { Component } from "react";
import classNames from "classnames";
import { ButtonGroup } from "react-bootstrap";
import StyleButton from "./StyleButton.react";

class EntityControls extends Component {
  render() {
    const { entityControls, editorState, additionalClasses, svgClasses } = this.props;
    const currentStyle = editorState.getCurrentInlineStyle();
    const btnGroupClasses = classNames({
      "RichEditor-controls": true,
      [additionalClasses]: true
    });

    return (
      <ButtonGroup className={btnGroupClasses}>
        {entityControls.map(type =>
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            icon={type.icon}
            onToggle={type.action}
            svgClasses={svgClasses}
          />
        )}
      </ButtonGroup>
    );
  }
}

export default EntityControls;