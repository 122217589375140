"use strict";
import { Component } from "react";
import PropTypes from "prop-types";
import compact from "lodash/compact";
import includes from "lodash/includes";
import map from "lodash/map";
import pull from "lodash/pull";
import orderBy from "lodash/orderBy";
import { UI_COLORS } from "../constants/Constants";

const DEFAULT_ITEM_COLOR = "#666";

class Filter extends Component {
  constructor(props) {
    super(props);
    [
      "triggerItemSelection",
      "selectAll",
      "clear",
      "renderClearOrSelectButton",
      "renderOptions",
      "renderSearchBar",
      "onInputChange"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
  }

  triggerItemSelection(itemId) {
    const { multipleSelect, allowUnselect, selectedItemIds, intermediateItemIds, closeParent, onChange } = this.props;
    let newSelectedItemIds = selectedItemIds.slice();
    let newInterdiamediateIds = intermediateItemIds.slice();
    const addOrRemove = (array, id) => {
      if (array.includes(id)) {
        pull(array, id);
      } else {
        array.push(id);
      }
    };

    if (multipleSelect) {
      addOrRemove(newSelectedItemIds, itemId);
    } else if (allowUnselect && newSelectedItemIds.includes(itemId)) {
      newSelectedItemIds = [];
    } else {
      newSelectedItemIds = [itemId];
    }
    if (newInterdiamediateIds.includes(itemId)) {
      pull(newInterdiamediateIds, itemId);
      pull(newSelectedItemIds, itemId);
    }
    onChange({ selectedItemIds: newSelectedItemIds, intermediateItemIds: newInterdiamediateIds });
    if (!multipleSelect && closeParent) {
      closeParent();
    }
  }

  selectAll() {
    const { itemIdKey, onChange, items } = this.props;
    const selectedItemIds = map(items, itemIdKey);
    onChange({ selectedItemIds });
  }

  onInputChange(e) {
    this.props.onChange({ searchValue: e.target.value });
  }

  clear() {
    this.props.onChange({ selectedItemIds: [], intermediateItemIds: [] });
  }

  renderSearchBar() {
    const { hasSearch, translationKey } = this.props;
    const placeholder = translationKey ? I18n.t(`react.reports.${translationKey}.filter_placeholder`) : I18n.t("react.reports.filter");
    return hasSearch ? (
      <div className="search-input">
        <input type="text" className="form-control" placeholder={placeholder} onChange={this.onInputChange} />
      </div>
    ) : "";
  }

  renderCreateBar() {
    const { hasCreate, onCreate, searchValue } = this.props;
    if (!hasCreate || !onCreate || !searchValue || searchValue.length === 0) return null;

    return <button className="btn btn-primary mb-10 d-grid" style={{ paddingLeft: "10px", textAlign: "left" }} onClick={onCreate(searchValue)}>
      {I18n.t("create")} <span className="badge rounded-pill big" style={{ backgroundColor: "grey" }}>{ searchValue }</span>
    </button>;
  }

  renderClearOrSelectButton() {
    const { hasSelectAll, searchValue, selectedItemIds } = this.props;
    if (searchValue && searchValue.length > 0) {
      return null;
    }
    return hasSelectAll ? (
      (selectedItemIds.length == 0) ? (
        <div key={-1} className="list-group-item list-group-item-action" onClick={this.selectAll} id="select-all">
          <div className="d-flex align-items-center list-group-item-content">
            <div className="icon-width-keeper mr-5"></div>
            <div className="flex-grow-1">
              <span className="name"><strong>{I18n.t("react.reports.select_all")}</strong></span>
            </div>
          </div>
        </div>
      ) : (
        <div key={-1} className="list-group-item list-group-item-action" onClick={this.clear} id="clear-all">
          <div className="d-flex align-items-center list-group-item-content">
            <div className="icon-width-keeper mr-5"></div>
            <div className="flex-grow-1">
              <span className="name"><strong>{I18n.t("react.reports.clear")}</strong></span>
            </div>
          </div>
        </div>
      )
    ) : "";
  }

  renderOptions() {
    const { items, multipleSelect, itemIdKey, itemNameKey, itemColorKey, itemIconKey, searchValue,
      selectedItemIds, intermediateItemIds, baseKey, actionButtons, additionalInfo, roundUpTheTopOfFirstOption } = this.props;
    const colorKey = itemColorKey;
    if (!items) return;

    const sortedItems = orderBy(items, [item => item[itemNameKey].toLowerCase()], ["asc"]);

    const listItems = compact(sortedItems.map((item, index) => {
      let beforeName = "";
      let afterName = "";
      let selected = false;
      let buttonsAction = null;
      if (searchValue && searchValue.length > 0 && !includes(item[itemNameKey].toLowerCase(), searchValue.toLowerCase())) {
        return null;
      }
      let fontColor = "";
      const labelColor = item[colorKey] || DEFAULT_ITEM_COLOR;
      let backgroundColorStyle = {};
      if (includes(selectedItemIds, item[itemIdKey])) {
        beforeName = <i className="fa-regular fa-check"> </i>;
        selected = true;
        buttonsAction = actionButtons && actionButtons(item);
      } else if (includes(intermediateItemIds, item[itemIdKey])) {
        beforeName = (<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14'>
          <line x1="1" x2="14" y1="10" y2="10" stroke={labelColor} fill="transparent" strokeWidth="3"/>
        </svg>);
        selected = true;
      }
      if (selected) {
        afterName = multipleSelect ? <i className="fa-regular fa-xmark"> </i> : "";
        fontColor = UI_COLORS.brand700;
        backgroundColorStyle = { backgroundColor: UI_COLORS.brand50 };
      }

      let color = "";
      if (labelColor != DEFAULT_ITEM_COLOR) {
        color = <span className="color" style={{ backgroundColor: labelColor, marginRight: "2px" }}> </span>;
      }

      let icon = "";
      if (item[itemIconKey]) {
        icon = <span className={item[itemIconKey]}> </span>;
      }
      if (index === 0 && roundUpTheTopOfFirstOption) {
        backgroundColorStyle = { ...backgroundColorStyle, borderTopLeftRadius: "5px", borderTopRightRadius: "5px", borderTopWidth: "0px" };
      }

      return (
        <div key={baseKey + index} className="list-group-item list-group-item-action" onClick={this.triggerItemSelection.bind(null, item[itemIdKey])} style={backgroundColorStyle}>
          <div className="d-flex align-items-center list-group-item-content">
            <div className="icon-width-keeper mr-5">
              <span style={{ color: fontColor }}> {beforeName} </span>
            </div>
            <div className="flex-grow-1">
              <span style={{ color: fontColor }}>
                {color} {icon} {item[itemNameKey]} {additionalInfo && additionalInfo(item)}
              </span>
            </div>

            {multipleSelect && <span className="icon-width-keeper ml-5" style={{ color: fontColor }}>{afterName}</span>}

            {buttonsAction}
          </div>
        </div>
      );
    }));
    return listItems;
  }

  render() {
    const { maxHeight, translationKey } = this.props;
    const styles = maxHeight ? { maxHeight, overflow: "auto" } : {};
    return (
      <div className="filter-main">
        {this.renderSearchBar()}
        {this.renderCreateBar()}
        <div className="filter-card list-group" id={`${translationKey}-filter-card`} style={styles}>
          { this.renderClearOrSelectButton() }
          { this.renderOptions() }
        </div>
      </div>
    );
  }
}

export default Filter;
export { Filter }; // pure component. Used in tests

Filter.propTypes = {
  multipleSelect: PropTypes.bool.isRequired,
  selectedItemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  intermediateItemIds: PropTypes.arrayOf(PropTypes.string),
  itemIdKey: PropTypes.string,
  itemColorKey: PropTypes.string,
  itemIconKey: PropTypes.string,
  searchValue: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  closeParent: PropTypes.func,
  baseKey: PropTypes.string,
  roundUpTheTopOfFirstOption: PropTypes.bool,
};

Filter.defaultProps = {
  multipleSelect: true,
  selectedItemIds: [],
  intermediateItemIds: [],
  searchValue: "",
  itemIdKey: "id",
  itemNameKey: "name",
  itemColorKey: "color",
  itemIconKey: "icon",
  items: [],
  onChange: null,
  closeParent: null,
  allowUnselect: false,
  hasSearch: false,
  hasCreate: false,
  onCreate: null,
  hasSelectAll: false,
  baseKey: "",
  maxHeight: null,
  roundUpTheTopOfFirstOption: false,
};
