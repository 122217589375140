import { UI_COLORS } from "../../constants/Constants.js";

interface Props {
  children: JSX.Element;
}

const WhiteText: React.FC<Props> = (props) => {

  return (
    <span style={{ backgroundColor: UI_COLORS.grey700, display: "inline-block" }}>{props.children}</span>
  );
};

export default WhiteText;
