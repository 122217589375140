import { Component } from "react";
import { Entity } from "draft-js";

class Link extends Component {
  render() {
    const { href } = Entity.get(this.props.entityKey).getData();
    return (
      <a href={href} className="drafjs-bhe_link">
        {this.props.children}
      </a>
    );
  }
}

export default Link;