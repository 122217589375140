import { Component } from "react";
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
  Entity,
  Modifier,
  RichUtils
} from "draft-js";
import DraftPasteProcessor from "draft-js/lib/DraftPasteProcessor";
import findEntities from "../../utils/rich_text_editor/findEntities";
import DropField from "../rich_text_editor/DropField.react";
import Link from "../rich_text_editor/Link";
import FontSizeSelect from "../rich_text_editor/FontSizeSelect.react";
import EntityControls from "../rich_text_editor/EntityControls.react";
import ColorPicker from "../../components/ColorPicker.react";
import FilterDropdown from "../FilterDropdown.react";
import { Button, ButtonGroup } from "react-bootstrap";

import { stateToHTML } from "draft-js-export-html"; //https://github.com/sstur/draft-js-utils/tree/master/packages/draft-js-export-html
import Editor from "@draft-js-plugins/editor"; //https://www.draft-js-plugins.com/
import createStaticToolbarPlugin from "@draft-js-plugins/static-toolbar";
import buttonStyles from "../../components/rich_text_editor/buttonsStyles.module.css";
import toolbarStyles from "../../components/rich_text_editor/toolbarStyles.module.css";
import createTextAlignmentPlugin from "../../utils/rich_text_editor/justify/textAlignmentPlugin/index";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from "@draft-js-plugins/buttons";
import StyleButton from "../rich_text_editor/StyleButton.react";
import { getSelectionCustomInlineStyle, getStyleAtOffset } from "../../utils/rich_text_editor/draftjs-utils/inline";
import { getLumaFromColor } from "../../utils/rich_text_editor/draftjs-utils/common";
import WhiteText from "../../components/rich_text_editor/WhiteText.react";
import { UI_COLORS } from "../../constants/Constants";
import { getColorAtOffsetOrDefault } from "../../utils/rich_text_editor/draftjs-utils/inline";
import TextGeneratorButton from "../../text_generator/TextGeneratorButton.react";
import reduce from "lodash/reduce";

// Custom styles displayed
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: "'Inconsolata', 'Menlo', 'Consolas', 'monospace'",
    padding: 2
  }
};

const fontColorDynamicStyle = {
  prefix: "fontColor",
  cssTag: "color",
  jsxCssTag: "color"
};

const fontSizeDynamicStyle = {
  prefix: "fontSize",
  cssTag: "font-size",
  jsxCssTag: "fontSize"
};

const RTE_FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96];

const dynamicStyles = [fontColorDynamicStyle, fontSizeDynamicStyle];

const DROP_FIELD_REGEX = /<<([\w. _]+)>>/g;
export const DEFAULT_FONT_COLOR = UI_COLORS.grey700;


const customDecorators = [
  {
    strategy: guestFieldTagStrategy,
    component: DropField
  },
  {
    strategy: findEntities.bind(null, "LINK"),
    component: Link
  },
  {
    strategy: whiteCharacterStrategy,
    component: WhiteText
  }
];

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

function guestFieldTagStrategy(contentBlock, callback) {
  findWithRegex(DROP_FIELD_REGEX, contentBlock, callback);
}

function whiteCharacterStrategy(contentBlock, callback) {
  const characterList = contentBlock.getCharacterList();
  characterList.forEach((char, offset) => {
    const colorStyleAtOffset = getStyleAtOffset(contentBlock, "fontColor", offset);
    if (colorStyleAtOffset === undefined) { return; }

    const colorAtOffset = colorStyleAtOffset.replace("fontColor", "");
    const luma = getLumaFromColor(colorAtOffset);
    if (luma > 150) { callback(offset, offset + 1); }
  });
}

class RichTextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: null,
      styleMap,
      dynamicStyleMap: {}
    };

    this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
    this.dynamicInlineTagMap = {};
    this.getBlockStyle = (block) => this._getBlockStyle(block);
    this.onChange = this.onChange.bind(this);
    this.addLink = this._addLink.bind(this);
    this.removeLink = this._removeLink.bind(this);
    this.sendHtmlToParent = this.sendHtmlToParent.bind(this);
    this.setFonSizeStyle = this.setFonSizeStyle.bind(this);
    this.setColorStyle = this.setColorStyle.bind(this);
    this.injectDynamicExclusiveInlineStyle = this.injectDynamicExclusiveInlineStyle.bind(this);
    this.registerDynamicStyle = this.registerDynamicStyle.bind(this);
    this.addDropField = this._addDropField.bind(this);
    this.updateWithProps = this.updateWithProps.bind(this);
    this.clearInlineStyles = this.clearInlineStyles.bind(this);
    this.setDomEditorRef = ref => this.domEditor = ref;
    this.handleKeyCommand = (command) => this._handleKeyCommand(command);
    this.onTextInjection = this.onTextInjection.bind(this);
    this.renderAiAssistantButton = this.renderAiAssistantButton.bind(this);
    this.ENTITY_CONTROLS = [
      { label: "Add Link", action: this.addLink, icon: "fa-regular fa-link" },
      { label: "Remove Link", action: this.removeLink, icon: "fa-regular fa-chain-broken" }
    ];

    const textAlignmentPlugin = createTextAlignmentPlugin();
    const staticToolbarPlugin = createStaticToolbarPlugin({
      theme: { buttonStyles, toolbarStyles }, //toolbarStyles.module.css is empty but needed for theme initialization.
    });
    this.PluginComponents = {
      Toolbar: staticToolbarPlugin.Toolbar,
      textAlignmentPlugin: textAlignmentPlugin

    };
    this.plugins = [staticToolbarPlugin, textAlignmentPlugin];
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(
        this.state.editorState,
        inlineStyle
      )
    );
  }

  updateWithProps(props) {
    if (this.state.editorState) return;
    const { processHTML } = DraftPasteProcessor;
    const { content, draftjsContent } = props;
    let editorState = EditorState.createEmpty();
    let contentState = null;
    if (draftjsContent && Object.keys(draftjsContent).length > 0) {
      contentState = convertFromRaw(draftjsContent);
    } else {
      let htmlToProcess = content || "";
      htmlToProcess = htmlToProcess.replace(DROP_FIELD_REGEX, "&lt;&lt;$1&gt;&gt;");
      // Upgrading DraftJs from 0.10.1 to 0.11.7 doesn't allow to do contentState.hasText() in the below render() function.
      //if contentState is created from empty BlockArray
      // ContentState has to be creating from empty text now
      const processedHtml = processHTML(htmlToProcess);
      contentState = processedHtml.contentBlocks.length === 0 ? ContentState.createFromText("") : ContentState.createFromBlockArray(processedHtml);
    }
    if (contentState) {
      editorState = EditorState.push(editorState, contentState);
      this.setState({ editorState });
      //build dynamic inline style map according to what's found

      const dynamicStylesToRegister = [];
      this.allInlineStyleUsed(editorState).forEach(style => {
        const dynamicStyle = dynamicStyles.find(ds => style.startsWith(ds.prefix));
        if (dynamicStyle) {
          dynamicStylesToRegister.push({
            dynamicStyle,
            value: style.split(dynamicStyle.prefix)[1]
          });
        }
      });
      this.registerDynamicStyle(...dynamicStylesToRegister);
    }
  }

  componentDidMount() {
    this.updateWithProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateWithProps(nextProps);
  }

  allInlineStyleUsed(editorState) {
    let existingStyles = [];
    convertToRaw(editorState.getCurrentContent()).blocks.forEach(block => {
      existingStyles = existingStyles.concat(block.inlineStyleRanges.map(styleRange => styleRange.style));
    });
    return existingStyles;
  }

  _addDropField(text) {
    if (text instanceof Array && text.length == 0) return;
    const { editorState } = this.state;
    const currentContent = editorState.getCurrentContent();
    const entityKey = Entity.create("DROP_FIELD", "IMMUTABLE", { text });
    const currentSelectionState = editorState.getSelection();
    const startKey = currentSelectionState.getStartKey();
    const startOffset = currentSelectionState.getStartOffset();
    const block = currentContent.getBlockForKey(startKey);
    const inlineStyles = block.getInlineStyleAt(startOffset);

    let dropFieldReplacedContent = Modifier.insertText(
      currentContent,
      currentSelectionState,
      `<<${text}>>`,
      inlineStyles,
      entityKey
    );

    // A space is appended right after for a smooth writing experience.
    dropFieldReplacedContent = Modifier.insertText(
      dropFieldReplacedContent,
      dropFieldReplacedContent.getSelectionAfter(),
      " ",
      inlineStyles
    );

    let newEditorState = EditorState.push(
      editorState,
      dropFieldReplacedContent,
      "insert-drop-field"
    );
    newEditorState = EditorState.forceSelection(newEditorState, dropFieldReplacedContent.getSelectionAfter());
    this.onChange(newEditorState);
  }

  setFonSizeStyle(size) {
    this.injectDynamicExclusiveInlineStyle(fontSizeDynamicStyle, `${size}px`);
  }

  setColorStyle(color) {
    this.injectDynamicExclusiveInlineStyle(fontColorDynamicStyle, color);
  }

  injectDynamicExclusiveInlineStyle(dynamicStyle, value) {
    const { editorState } = this.state;
    const { prefix } = dynamicStyle;
    const inlineStyle = `${prefix}${value}`;

    // inject new style in the dynamic style map
    this.registerDynamicStyle({
      dynamicStyle,
      value
    });

    const selection = editorState.getSelection();

    let nextContentState = editorState.getCurrentContent();

    const stylesToRemove = this.allInlineStyleUsed(editorState).filter(style => style.startsWith(prefix));

    // remove all previously set fontSize style on the current selection
    stylesToRemove.forEach(style => {
      nextContentState = Modifier.removeInlineStyle(nextContentState, selection, style);
    });

    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "change-inline-style"
    );

    // push the new style
    nextEditorState = RichUtils.toggleInlineStyle(
      nextEditorState,
      inlineStyle
    );

    this.onChange(nextEditorState);
  }

  // expecting opts to be { dynamicStyle, value }
  registerDynamicStyle(...opts) {
    let nextDynamicStyleMap = Object.assign({}, this.state.dynamicStyleMap);
    opts.forEach(opt => {
      const { dynamicStyle, value } = opt;
      const inlineStyle = `${dynamicStyle.prefix}${value}`;
      nextDynamicStyleMap = Object.assign({}, nextDynamicStyleMap, { [inlineStyle]: { [dynamicStyle.jsxCssTag]: value } });
      this.dynamicInlineTagMap = Object.assign({}, this.dynamicInlineTagMap, { [inlineStyle]: [`<span style='${dynamicStyle.cssTag}: ${value};'>`, "</span>"] });
    });

    this.setState({
      dynamicStyleMap: nextDynamicStyleMap
    });
  }

  blockStyleObject(block) {
    const textAlign = this.getBlockAlignment(block);
    const type = block.getType();

    const colorAtOffset = getColorAtOffsetOrDefault(block, 0);
    if (type === "ordered-list-item" || type === "unordered-list-item") {
      return {
        style: { textAlign: textAlign, color: colorAtOffset },
      };
    }
    if (typeof textAlign === "string" && textAlign.length === 0) return;
    if (textAlign) {
      return {
        element: "span",
        style: { textAlign: textAlign },
      };
    }
  }

  inlineStyleObject(styles) {
    let colorKey = "fontColor";
    let sizeKey = "fontSize";
    let color = styles.filter((value) => value.startsWith(colorKey)).first();
    let size = styles.filter((value) => value.startsWith(sizeKey)).first();
    let styleElements = {
      element: "span",
      style: {}
    };
    if (color) { styleElements.style.color = color.replace(colorKey, ""); }
    if (size) { styleElements.style.fontSize = size.replace(sizeKey, ""); }
    return styleElements;
  }

  entityStyleObject(entity) {
    const entityType = entity.get("type").toLowerCase();
    if (entityType === "link") {
      const data = entity.getData();
      return {
        element: "a",
        attributes: {
          href: data.url,
          target: "_blank",
          rel: "noopener noreferrer"
        }
      };
    }
  }

  sendHtmlToParent() {
    const { editorState } = this.state;
    const { onBlur } = this.props;
    const raw = convertToRaw(editorState.getCurrentContent());

    let options = {
      blockStyleFn: (block) => { return this.blockStyleObject(block); },
      inlineStyleFn: (styles) => { return this.inlineStyleObject(styles); },
      entityStyleFn: (entity) => { return this.entityStyleObject(entity); },
      defaultBlockTag: "div"
    };

    let html = this.cleanStateToHTML(editorState.getCurrentContent(), options);
    onBlur(html, raw);
  }

  cleanStateToHTML(content, options) {
    //stateToHTML keep "\n" in html string after line break character : https://github.com/sstur/draft-js-utils/blob/85b9d7e4181202adb99a22ea410922694c3347f8/packages/draft-js-export-html/src/stateToHTML.js#L499
    //this "\n" will be replace by "\r\n" if html is used in a FormData here js/components/shared/AccesspointForm.react.js:152
    //this will double <br> when converting into HTML, we need to remove it.
    return stateToHTML(content, options).replaceAll("\n", "");
  }

  onChange(editorState) {
    this.setState({ editorState }, () => {
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => {
        this.sendHtmlToParent();
      }, 800);
    });
  }

  _addLink() {
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) return;
    const url = window.prompt(I18n.t("react.rich_text_editor.enter_url"));
    if (url === null) return;
    const entityKey = Entity.create("LINK", "MUTABLE", { url });
    this.onChange(RichUtils.toggleLink(editorState, selection, entityKey));
  }

  _removeLink() {
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) return;
    this.onChange(RichUtils.toggleLink(editorState, selection, null));
  }

  _getBlockStyle(block) {
    if (block.getType() == "blockquote") return "RichEditor-blockquote";
    let alignment = this.getBlockAlignment(block);
    if (block.getType() === "ordered-list-item" || block.getType() === "unordered-list-item") {
      this.appendCssClassToDomHead(block);
      return `color-for-${block.getKey()}`;
    }
    if (!block.getText()) {
      const previousBlock = this.state.editorState.getCurrentContent().getBlockBefore(block.getKey());
      if (previousBlock) alignment = this.getBlockAlignment(previousBlock);
    }
    return `alignment--${alignment.toLowerCase()}`;
  }

  getBlockAlignment(block) {
    let style = "left";
    block.findStyleRanges(function(e) {
      // Website created with old editor send "CENTER" alignment property, so we need to check for both "center" and "CENTER" property in html. Same thing for left and right ...
      if (e.hasStyle("center") || e.hasStyle("CENTER")) style = "center";
      if (e.hasStyle("right") || e.hasStyle("RIGHT")) style = "right";
      if (e.hasStyle("justify") || e.hasStyle("JUSTIFY")) style = "justify";
    });
    return style;
  }

  appendCssClassToDomHead(block) {
    const colorAtOffset = getColorAtOffsetOrDefault(block, 0);
    const cssClass = `.color-for-${block.getKey()} { color: ${colorAtOffset}; }`;
    const head = document.head || document.getElementsByTagName("head")[0];
    const style = document.createElement("style");

    head.appendChild(style);
    style.appendChild(document.createTextNode(cssClass));
  }

  dropFieldsDropDown() {
    const { dropFields, baseKey } = this.props;
    if (!dropFields || dropFields.length == 0) { return; }
    return <ButtonGroup className={`RichEditor-controls ${this.toolbarSizeClass()}`}>
      <div>
        <FilterDropdown
          id="draft"
          items={dropFields}
          multipleSelect={false}
          hasSelectAll={false}
          onChange={this.addDropField}
          showCells={false}
          translationKey="draft"
          selectedItemIds={[]}
          title={I18n.t("react.rich_text_editor.drop_fields")}
          popoverAdditionalClasses={`btn-${this.toolbarSize()} btn-font-size-${this.toolbarSize()}`}
          sortItems={true}
          wrapperClasses={this.toolbarSizeClass()}
          baseKey={baseKey}
        />
      </div>
    </ButtonGroup>;
  }

  toolbarSizeClass() {
    return `btn-group-${this.toolbarSize()}`;
  }

  toolbarSize() {
    const { toolbarSize } = this.props;
    switch (toolbarSize) {
    case "large": {
      return "lg";
    }
    case "small": {
      return "sm";
    }
    case "extra-small": {
      return "xs";
    }
    default:
      return "";
    }
  }

  buttonStyles(externalProps) {
    return {
      buttonWrapper: `${this.toolbarSizeClass()} btn-group`,
      button: `RichEditor-styleButton btn btn-secondary rte-${this.toolbarSize()}-svg custom-rte-btn`,
      active: externalProps.theme.active
    };
  }

  clearInlineStyles() {
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    const contentWithoutStyles = reduce(this.allInlineStyleUsed(editorState), (newContentState, style) => (
      // Remove all color/font/bold/italic/... styles (inline styles)
      Modifier.removeInlineStyle(
        newContentState,
        selection,
        style
      )
    ), editorState.getCurrentContent());

    let newEditorState = EditorState.push(
      editorState,
      contentWithoutStyles,
      "remove-style"
    );

    newEditorState = RichUtils.toggleBlockType(newEditorState, "unstyled"); // Replace all ul/h1/... by p (block styles)
    newEditorState = RichUtils.toggleLink(newEditorState, selection, null); // Remove links
    this.onChange(newEditorState);
  }

  _handleKeyCommand(command) {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  rubber() {
    return <ButtonGroup className={`RichEditor-controls ${this.toolbarSizeClass()}`}>
      <Button variant="secondary" className={`custom-rte-btn btn rte-${this.toolbarSize()}-svg`} onClick={this.clearInlineStyles} >
        <i className="fa-regular fa-eraser"></i>
      </Button>
    </ButtonGroup>;
  }

  isAutoTranslatedButton() {
    const { isAutoTranslated } = this.props;
    if (!isAutoTranslated) return null;

    return <ButtonGroup className={`RichEditor-controls ${this.toolbarSizeClass()}`}>
      <Button variant="secondary" className={`custom-rte-btn btn rte-${this.toolbarSize()}-svg`} title={ I18n.t("react.translations.automatic.auto_translated") } >
        <i className="fa-regular fa-language"></i>
      </Button>
    </ButtonGroup>;
  }

  renderAiAssistantButton() {
    const { aiModalSize, context, textGeneratorPrePromptKey } = this.props;
    if (context === "website") return null;

    return (
      <TextGeneratorButton
        context={context || "backoffice"}
        onSubmitGeneratedText={this.onTextInjection}
        forceToUseBasePrompt={true}
        buttonLabel={I18n.t("react.rich_text_editor.ai_assistant")}
        modalTitle={I18n.t("react.rich_text_editor.ai_assistant")}
        buttonClassesOverride={`btn btn-secondary btn-font-size-${this.toolbarSize()}`}
        modalSize={aiModalSize}
        prePromptKey={textGeneratorPrePromptKey}
      />
    );
  }

  currentSelectedFontSize() {
    const { editorState } = this.state;
    const selectionCustomInlineStyle = getSelectionCustomInlineStyle(editorState, ["fontSize"]);

    if (selectionCustomInlineStyle.fontSize) return selectionCustomInlineStyle.fontSize.match(/\d+/)[0];
    return "16";
  }

  currentSelectedFontColor() {
    const { editorState } = this.state;
    const selectionCustomInlineStyle = getSelectionCustomInlineStyle(editorState, ["fontColor"]);

    if (selectionCustomInlineStyle["fontColor"]) {
      return selectionCustomInlineStyle.fontColor.replace("fontColor", "");
    }
    return DEFAULT_FONT_COLOR;
  }

  onTextInjection(generatedText) {
    const { editorState } = this.state;
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const entityKey = Entity.create("MENTION", "IMMUTABLE", {});
    const textWithEntity = Modifier.insertText(currentContent, selection, generatedText, null, entityKey);
    const newEditorState = EditorState.push(editorState, textWithEntity, "insert-characters");
    this.onChange(newEditorState);
  }

  render() {
    const { editorState, styleMap, dynamicStyleMap } = this.state;
    const { Toolbar, textAlignmentPlugin } = this.PluginComponents;
    if (!editorState) return <div></div>;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = "RichEditor-editor";
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }

    // For some reasons (react mechanisms), the Toolbar must be rendered after the Editor
    // So we just use css to inverse the display order and have the toolbar above
    // https://github.com/draft-js-plugins/draft-js-plugins/issues/1369#issuecomment-746169201
    return (
      <div className="RichEditor-root" style={{ display: "flex", flexDirection: "column-reverse" }}>
        <div className={className}>
          <Editor
            editorState={editorState}
            onChange={this.onChange}
            plugins={this.plugins}
            blockStyleFn={this.getBlockStyle}
            customStyleMap={Object.assign({}, styleMap, dynamicStyleMap)}
            spellCheck={true}
            onBlur={this.sendHtmlToParent}
            ref={this.setDomEditorRef}
            decorators={customDecorators}
            handleKeyCommand={this.handleKeyCommand}
          />
        </div>
        <Toolbar>
          {
            (externalProps) => (
              <div className="btn-toolbar">
                <ButtonGroup className={`RichEditor-controls ${this.toolbarSizeClass()}`}>
                  <HeadlineOneButton {...externalProps} theme={this.buttonStyles(externalProps)} />
                  <HeadlineTwoButton {...externalProps} theme={this.buttonStyles(externalProps)} />
                  <HeadlineThreeButton {...externalProps} theme={this.buttonStyles(externalProps)} />
                  <BlockquoteButton {...externalProps} theme={this.buttonStyles(externalProps)} />
                  <UnorderedListButton {...externalProps} theme={this.buttonStyles(externalProps)} />
                  <OrderedListButton {...externalProps} theme={this.buttonStyles(externalProps)} />
                </ButtonGroup>
                <ButtonGroup className={`RichEditor-controls ${this.toolbarSizeClass()}`}>
                  <BoldButton {...externalProps} theme={this.buttonStyles(externalProps)} />
                  <ItalicButton {...externalProps} theme={this.buttonStyles(externalProps)} />
                  <UnderlineButton {...externalProps} theme={this.buttonStyles(externalProps)} />
                  <CodeButton {...externalProps} theme={this.buttonStyles(externalProps)} />
                  <ButtonGroup className={`${this.toolbarSizeClass()}`}>
                    <StyleButton
                      key={"strikethrough"}
                      active={editorState.getCurrentInlineStyle().has("STRIKETHROUGH")}
                      label="STRIKETHROUGH"
                      style="STRIKETHROUGH"
                      icon="fa-regular fa-strikethrough"
                      onToggle={this.toggleInlineStyle}
                      svgClasses={`rte-${this.toolbarSize()}-svg`}
                    />
                  </ButtonGroup>
                </ButtonGroup>
                <ButtonGroup className={`RichEditor-controls ${this.toolbarSizeClass()}`}>
                  <textAlignmentPlugin.TextAlignment {...externalProps} theme={this.buttonStyles(externalProps)} />
                </ButtonGroup>
                <EntityControls
                  editorState={editorState}
                  entityControls={this.ENTITY_CONTROLS}
                  svgClasses={`rte-${this.toolbarSize()}-svg`}
                  additionalClasses={`${this.toolbarSizeClass()}`}
                />
                <ButtonGroup className={`RichEditor-controls ${this.toolbarSizeClass()}`}>
                  <FontSizeSelect
                    values={RTE_FONT_SIZES}
                    onSelect={this.setFonSizeStyle}
                    additionalClasses={this.toolbarSizeClass()}
                    svgClasses={`fontsize-picker-${this.toolbarSize()}`}
                    selectedValue={this.currentSelectedFontSize()}
                  />
                </ButtonGroup>
                <ButtonGroup className={`RichEditor-controls ${this.toolbarSizeClass()}`}>
                  <ColorPicker
                    setColor={this.setColorStyle}
                    additionalClasses="custom-rte-btn"
                    svgClasses={`color-picker-${this.toolbarSize()}`}
                    displayColorSquare={true}
                    selectedColor={this.currentSelectedFontColor()}
                    colorSquareClass={`color-square-${this.toolbarSize()}`} />
                </ButtonGroup>
                { this.dropFieldsDropDown() }
                { this.rubber() }
                { this.isAutoTranslatedButton() }
                <ButtonGroup className={`RichEditor-controls ${this.toolbarSizeClass()}`}>
                  { this.renderAiAssistantButton() }
                </ButtonGroup>
              </div>
            )
          }
        </Toolbar>

      </div>
    );
  }
}

export default RichTextEditor;
